import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { headData } from '../mock/data';
import '../../mystyles.scss';

export default () => {
  const { title, lang, description } = headData;
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = () => console.log('fullname', fullName, 'email', email);
  const handleFullName = e => setFullName(e.target.value);
  const handleEmail = e => setEmail(e.target.value);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description} />
      </Helmet>
      <div className="container">
        <section className="section">
          <div className="field">
            <label htmlFor="fullName" className="label">
              Full Name
              <div className="control">
                <input
                  id="fullName"
                  className="input"
                  type="text"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={handleFullName}
                />
              </div>
            </label>
          </div>

          <div className="field">
            <label htmlFor="email" className="label">
              Email
              <div className="control">
                <input
                  id="email"
                  className="input"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmail}
                />
              </div>
            </label>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button type="submit" className="button is-link" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
